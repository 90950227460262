//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/

@font-face {
  font-family: "Zapf Renaissance";
  src: url('../../../../public/images/fonts/zapf-renaissance-book/zapf-renaissance-book.ttf')  format('truetype');
}

@font-face {
  font-family: "Sequel Sans Medium";
  src: url('../../../../public/images/fonts/sequel-sans-otf/Sequel Sans Medium Body.otf')  format('truetype');
}

@font-face {
  font-family: "Sequel Sans";
  src: url('../../../../public/images/fonts/sequel-sans-otf/Sequel Sans Book Body.otf')  format('truetype');
}

.font-zaf-renaissance {
  font-family: "Zapf Renaissance";
}

.font-sequel-sans {
  font-family: "Sequel Sans";
}

.font-sequel-sans-medium {
  font-family: "Sequel Sans Medium";
}



.font-size-96 {
  font-size: 96px;
}

@include media-breakpoint-down(sm) {
  .font-size-96 {
    font-size: 32px;
  }
}

.font-size-56 {
  font-size: 56px;
}

@include media-breakpoint-down(sm) {
  .font-size-56 {
    font-size: 24px;
  }
}

.font-size-32 {
  font-size: 32px;
}

@include media-breakpoint-down(sm) {
  .font-size-32 {
    font-size: 20px;
  }
}

.font-size-24 {
  font-size: 24px;
}

@include media-breakpoint-down(sm) {
  .font-size-24 {
    font-size: 18px;
  }
}

.font-size-18 {
  font-size: 18px;
}

@include media-breakpoint-down(sm) {
  .font-size-18 {
    font-size: 16px;
  }
}

.font-size-16 {
  font-size: 16px;
}

@include media-breakpoint-down(sm) {
  .font-size-16 {
    font-size: 14px;
  }
}

.font-size-14 {
  font-size: 14px;
}

@include media-breakpoint-down(sm) {
  .font-size-14 {
    font-size: 12px;
  }
}

//font weights

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-305 {
  font-weight: 305;
}

.font-weight-315 {
  font-weight: 315;
}


//font colors

.font-color-neutral-black {
  color: #131313;
}

.font-color-neutral-grey-1 {
  color: #555555;
}

.font-color-neutral-grey-2 {
  color: #808080;
}

.font-color-neutral-grey-3 {
  color: #CCCCCC;
}

.font-color-neutral-white {
  color: #FFFFFF;
}

.font-color-orange {
  color: #FF6F3C;
}

.font-color-dark-grey {
  color: #30302D;
}

.font-color-blue {
  color: #081250;
}

.font-color-green {
  color: #258E3F;
}

.font-color-light-brown {
  color: #E3DCD1;
}

.font-color-brand-light {
  color: #F6F4F0;
}

.font-opacity-60 {
  opacity: 60%;
}

.font-opacity-80 {
  opacity: 80%;
}

