
.bg-white {
  background: #FFFFFF;
}

.bg-orange {
  background-color: #FF6F3C;
}

.bg-blue {
  background-color: #081250;
}

.bg-navbar {
  background: rgba(0, 0, 0, 0.05);
  //backdrop-filter: blur(15px)
}

.bg-green-light {
  background: #D7F4DE;
}

.bg-light-red {
  background: #FFCFCC;
}


//home

.bg-home-hero-container {
  //min-height: 100vh;
  background: center linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 89.84%), url("../../../../public/images/home/bg-home-hero-image.png");
  background-size: cover;
}

.bg-promotion-container {
  background-image: url("../../../../public/images/home/bg-promotion-image.png");
  background-position: center;
  background-size: cover;
}


//achievements

.bg-achievements-container {
  background-image: url("../../../../public/images/achievements/bg-achievements-image.png");
  background-size: initial;
  background-position: left bottom;
  background-repeat: no-repeat;
}

@include media-breakpoint-down(sm) {
  .bg-achievements-container {
    background-image: url("../../../../public/images/achievements/bg-achievements-image.png");
    background-size: initial;
    background-position: center top;
    background-repeat: no-repeat;
  }
}

.bg-achievements-container-2 {
  background-image: url("../../../../public/images/achievements/bg-achievements-image.png");
  background-size: initial;
  background-position: right bottom;
  background-repeat: no-repeat;
}

@include media-breakpoint-down(sm) {
  .bg-achievements-container-2 {
    background-image: url("../../../../public/images/achievements/bg-achievements-image.png");
    background-size: initial;
    background-position: center top;
    background-repeat: no-repeat;
  }
}
