
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  font-family: "Sequel Sans" !important;
}

.nav-white-border {
  border: 1px solid #FFFFFF;
}

.nav-blue-border {
  border: 1px solid #081250;
}

.status-container {
  padding: 32px 20px 32px 20px;
  gap: 8px;
  border-width: 0px 10px 10px 0px;
  border-radius: 16px;
  background: #0812500F;
  border-style: solid;
  border-color: #081250;
}

@include media-breakpoint-down(sm) {
  .status-container {
    padding: 24px 16px 24px 16px;
  }
}

.about-us-container {
  padding: 48px 96px 48px 96px;
  border-radius: 200px 180px 0px 200px;
}

@include media-breakpoint-down(sm) {
  .about-us-container {
    padding: 24px;
    border-radius: 40px 40px 0px 40px;
  }
}

.best-project-container {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #CCCCCC;
}

.project-item {
  border-left: 5px solid #FF6F3C;
  border-radius: 10px;
}

.accordion-button {
  color: #081250 !important;
  font-size: 32px !important;
  font-weight: 400 !important;
}

@include media-breakpoint-down(sm) {
  .accordion-button {
    font-size: 16px !important;
    font-weight: 315 !important;
    padding-left: 0px;
  }
}

.accordion {
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-active-color: black !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  /* background-color: var(--bs-accordion-active-bg); */
  box-shadow: none !important;
}


.vertical-timeline-element-icon {
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

.vertical-timeline-element-content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.nav-link {
  color: #ffffff !important;
}

.nav-link:hover {
  color: #FF6F3C !important;
}

@include media-breakpoint-down(sm) {
  .nav-link {
    color: #081250 !important;
    padding: 0 !important;
  }
}

.dropdown-menu.show {
  border: none;
  margin: 0;
  padding: 0;
}

@include media-breakpoint-up(md) {
  .dropdown-menu.show {
    border: none;
    box-shadow: 0px 4px 6px -2px #1E293B08;

    box-shadow: 0px 12px 16px -4px #1E293B14;
    border: 1px solid #CCCCCC

  }
}


.other-page-nav-color .nav-item .nav-link {
  color: #081250 !important;
}

.other-page-nav-color .nav-item .nav-link:hover {
  color: #FF6F3C !important;
}

.navbar-scroll .nav-link {
  color: #081250 !important;
}

.services-button {
  cursor: pointer;
}

input:not([type="file"]) {
  padding: 16px 24px 16px 24px;
  color: #081250;
}

@include media-breakpoint-down(sm) {
  input:not([type="file"]) {
    padding: 16px;
  }
}

textarea {
  padding: 16px 24px 16px 24px !important;
}

@include media-breakpoint-down(sm) {
  textarea {
    padding: 16px !important;
  }
}

@include media-breakpoint-up(md) {
  input:not([type="file"]):hover {
    border: 1px solid #FF6F3C !important;
  }

  textarea:hover {
    border: 1px solid #FF6F3C !important
  }
}

input::placeholder {
  color: #808080 !important;
  font-size: 16px;
  font-weight: 305;
}

@include media-breakpoint-down(sm) {
  input::placeholder {
    font-size: 16px;
  }
}

textarea::placeholder {
  color: #808080 !important;
  font-size: 18px;
  font-weight: 305;
}

@include media-breakpoint-down(sm) {
  textarea::placeholder {
    font-size: 16px;
  }
}

textarea {
  color: #081250;
}

@include media-breakpoint-down(sm) {
  input::placeholder {
    font-size: 16px;
    font-weight: 305;
  }

  textarea::placeholder {
    font-size: 16px;
    font-weight: 305;
  }
}

input:focus {
  border: 1px solid #FF6F3C !important;
  outline: none;
  box-shadow: none !important;
}

textarea:focus {
  border: 1px solid #FF6F3C !important;
  outline: none;
  box-shadow: none !important;
}

.team-card {
  overflow: hidden;

  .team-details-container {
    bottom: -100%;
    overflow-y: scroll;
    left: 0;
    transform: translateX(0) scaleX(0);
    opacity: 0;
    background-color: #081250;
    border-radius: 16px;
    transition: bottom 0.5s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
}

@include media-breakpoint-down(sm) {
  .team-card {
    min-height: 50vh;
  }
}

.team-card:hover {
  .team-details-container {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0) scaleX(1);
    opacity: 1;
    overflow-y: scroll;
  }
}

.team-details-container::-webkit-scrollbar {
  visibility: hidden;
}

.nav-bar-item:hover {
  color: #FF6F3C;
}

.image-container .img-fluid {
  transition: transform 0.3s ease-in-out; /* Smooth scaling effect */
}

.image-container:hover .img-fluid {
  overflow: hidden;
  transform: scale(1.1); /* Scale up the image */
}


.filter-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #CCCCCC;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}

.filter-container.open {
  transform: translateY(0);
}


.services-swiper-container .swiper-pagination-bullet {
  background-color: #081250 !important;
  opacity: 20%;
}

.services-swiper-container .swiper-pagination-bullet-active {
  background-color: #081250 !important;
  opacity: 1;
}

@include media-breakpoint-up(md) {
  .job-container:hover {
    background-color: #FF6F3C;

    .job-container-icon {
      background-color: #081250 !important;
      color: #FF6F3C !important;
    }
  }
}


///* Hide the file input */
.file-input {
  //opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-file-upload {
  display: flex;
  padding: 8px 8px 8px 32px;
  align-items: center;
  justify-content: space-between;
  //flex-direction: row-reverse;/* Place elements on opposite sides */
  background-color: transparent; /* Background color for the input container */
}

@include media-breakpoint-down(sm) {
  .custom-file-upload {
    padding: 8px 8px 16px 16px;
  }
}

.custom-button:hover {
  background-color: #FF6F3C; /* Change color on hover */
  border: 2px solid #FF6F3C;
}


.main-heading {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.financial-graph-container {
  min-height: 100vh;
}

@include media-breakpoint-down(sm) {
  .financial-graph-container {
    min-height: 50vh;
  }
}

.container-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-label:hover input ~ .checkmark {
  background-color: #FFFFFF;
}

/* When the radio button is checked, add a blue background */
.container-label input:checked ~ .checkmark {
  background-color: #FF6F3C;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-label .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.line-height-32 {
  line-height: 130%;
}

@include media-breakpoint-down(sm) {
  .status-heading {
    white-space: normal;
    word-wrap: break-word;
    width: 70%;
  }
}
