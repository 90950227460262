.bg-contact-us-btn {
  padding: 12px 40px 12px 40px;
  gap: 8px;
  border-radius: 60px 100px 0px 60px;
}

.custom-button {
  display: inline-block;
  padding: 12px 40px 12px 40px;
  background-color: transparent; /* Customize button color */
  color: #081250; /* Customize text color */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer;
  border: 2px solid #081250;
}

@include media-breakpoint-down(sm) {
  .custom-button {
    padding: 12px;
  }
}

.br-32 {
  border-radius: 32px;
}

@include media-breakpoint-down(sm) {
  .br-32 {
    border-radius: 16px;
  }
}

.br-16 {
  border-radius: 16px;
}

.br-top-16 {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.br-12 {
  border-radius: 12px;
}

.br-8 {
  border-radius: 8px;
}

.br-4 {
  border-radius: 4px;
}

.br-3 {
  border-radius: 3px;
}

.form-field-border {
  border-bottom: 1px solid #E3DCD1;
}

.b-1-light-color {
  border: 1px solid #F6F4F0;
}

.b-1-grey-3 {
  border: 1px solid #CCCCCC;
}

.b-2-light-brown {
  border: 2px solid #E3DCD1;
}

.b-1-light-brown {
  border: 1px solid #E3DCD1;
}

.b-1-blue {
  border: 1px solid #081250;
}

.b-2-blue {
  border: 2px solid #081250;
}

.bb-1-light-brown {
  border-bottom: 1px solid #E3DCD1 !important;
}
